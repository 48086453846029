// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_container__J3rrq {\n  background: #5400c7;\n  min-height: 100%;\n}\n.styles_container__J3rrq .website-header {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 2.5em 0;\n}\n.styles_container__J3rrq .website-content {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}", ""]);
// Exports
exports.locals = {
	"container": "styles_container__J3rrq"
};
module.exports = exports;
