// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_container__3BS7R .ant-badge {\n  display: block;\n}\n.styles_container__3BS7R .ant-btn {\n  background: #fff;\n  padding: 0;\n  width: 55px;\n  height: 55px;\n}\n.styles_container__3BS7R .ant-btn .anticon {\n  font-size: 25px;\n}\n.styles_container__3BS7R .ant-btn:focus, .styles_container__3BS7R .ant-btn:hover {\n  color: #3dc1a2;\n  border-color: #3dc1a2;\n}", ""]);
// Exports
exports.locals = {
	"container": "styles_container__3BS7R"
};
module.exports = exports;
