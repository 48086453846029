// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_container__16hUY {\n  min-height: 100%;\n}\n.styles_container__16hUY .ant-menu {\n  background: transparent;\n  padding: 0;\n  height: 100%;\n}\n.styles_container__16hUY .ant-menu .ant-menu-item {\n  border-bottom: 1px solid #ecf5f6;\n  padding: 10px 20px;\n  height: auto;\n  margin: 0 !important;\n  color: #041430;\n  background: #fff;\n}\n.styles_container__16hUY .ant-menu .ant-menu-item:after {\n  display: none;\n}\n.styles_container__16hUY .ant-menu .ant-menu-item.ant-menu-item-selected {\n  background: #3dc1a2;\n  color: #fff;\n}\n.styles_container__16hUY .ant-menu .ant-menu-item:first-child {\n  border-top-left-radius: 20px;\n  border-top-right-radius: 20px;\n}\n.styles_container__16hUY .ant-menu .ant-menu-item:last-child {\n  border-bottom: none;\n  border-bottom-left-radius: 20px;\n  border-bottom-right-radius: 20px;\n}", ""]);
// Exports
exports.locals = {
	"container": "styles_container__16hUY"
};
module.exports = exports;
