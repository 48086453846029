import React, { memo, ReactElement, useEffect } from 'react';
import { Avatar, Button } from 'antd';
import clsx from 'clsx'
import { LikeOutlined, CommentOutlined, ShareAltOutlined, UserOutlined, PlusCircleFilled } from '@ant-design/icons';
import styles from "./styles.module.scss"
import { useIntl } from 'react-intl';

interface Address {
  address?: string
  city?: string
  country?: string
  region?: string
}

interface GridViewItemProps {
  id?: string;
  avatar?: string;
  firstName?: string;
  lastName?: string;
  specialty?: string;
  address?: Address;
  academicTitle?: string;
  onClick?: () => void;
  className?: string;
  area_of_expertize?: string;
  bio?: string;
}

export default memo(({ id, avatar, firstName, lastName, academicTitle, specialty, address, className, onClick, area_of_expertize, bio }: GridViewItemProps) => {
  const intl = useIntl()
  
  return (
    <div className={clsx(styles.container, 'medi-doctor-table-grid-view-item-component', className)} onClick={onClick}>
      <div>
        <Avatar src={avatar || undefined} icon={!avatar && <UserOutlined /> || undefined} shape="circle" size="large" />
        <div className="meta">
          <div className="name">{academicTitle}, Dr {firstName} {lastName}</div>
          <div className="specialty">{intl.formatMessage({ id: 'doctors.specialty' })} : {specialty}</div>
          <div className="location color">
            {address && address.city || ''}
            {address && address.country && `, ${address.country}` || ''}
          </div>
          {area_of_expertize &&
            <div className="area_of_expertize color">
              Area Of Expertize : {area_of_expertize}
            </div>
          }
        </div>
      </div>
      <div className="actions">
        <Button className="follow" shape="circle" onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
          <LikeOutlined className="icon" />
          <span>{intl.formatMessage({ id: 'doctors.follow' })}</span>
        </Button>
        <Button className="view-profile" shape="circle" onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
          <UserOutlined className="icon" />
          <span>{intl.formatMessage({ id: 'doctors.profile' })}</span>
        </Button>
      </div>
    </div>
  )
})
