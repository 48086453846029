import React, { memo, ReactElement, useEffect, useState } from 'react';
import { Avatar, Button, Dropdown, Menu, Space } from 'antd';
import clsx from 'clsx'
import { PlusCircleFilled, UserOutlined, DownOutlined, SmileOutlined, LikeOutlined  } from '@ant-design/icons';
import styles from "./styles.module.scss"
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import profileActions from '../../../../redux/profile/actions';

interface Address {
  address?: string
  city?: string
  country?: string
  region?: string
}
interface ListViewItemProps {
  id?: string;
  avatar?: string;
  firstName?: string;
  lastName?: string;
  specialty?: string;
  address?: Address;
  academicTitle?: string;
  onClick?: () => void;
  className?: string;
  area_of_expertize?: string;
  bio?: string;
  appreciations?: {id: number, fromUserId: number, toUserId: number }[];
  user_id: number;
  verified?: string;
  admin: number;
}

export default memo(({ id, avatar, firstName, lastName, academicTitle, specialty, address, className, onClick, area_of_expertize, user_id, appreciations, admin, verified }: ListViewItemProps) => {
  const intl = useIntl()
  const dispatch = useDispatch();
  function appreciation() {
    dispatch({
      type: profileActions.UPDATE_APPRECIATIONS,
      payload: {
        doctorId: id
      }
    })
    dispatch({
      type: 'doctors/GET_DOCTORS'
    })
  }
  const menu = (<Menu>
    <Menu.Item onClick={() => verification(true)}>Verified</Menu.Item>
    <Menu.Item onClick={() => verification(false)}>Not Verified</Menu.Item>
  </Menu>)
  function verification(verified:boolean) {
    dispatch({
      type: profileActions.UPDATE_DOCTOR_VERIFICATION,
      payload: {
        doctorId: id,
        verified
      }
    })
    dispatch({
      type: 'doctors/GET_DOCTORS'
    })
  }
  return (
    <div className={clsx(styles.container, 'medi-doctor-table-list-view-item-component', className)}>
      <div>
        <Avatar src={avatar || undefined} icon={!avatar && <UserOutlined /> || undefined} shape="circle" size="small" />
        <div className="meta">
          <div className="name">{academicTitle}, Dr {firstName} {lastName}</div>
          <div className="specialty">{intl.formatMessage({ id: 'doctors.specialty' })} : {specialty}</div>
          <div className="location color">
            {address && address.city || ''}
            {address && address.country && `, ${address.country}` || ''}
          </div>
          {area_of_expertize &&
            <div className="area_of_expertize color">
              Area Of Expertize : {area_of_expertize}
            </div>
          }
        </div>
      </div>
      <div className="actions">
        {admin === 1 && 
          <Dropdown overlay={menu}>
            <span>
              <Space>
                {verified ? "Verified" : "Not Verified"}
                <DownOutlined />
              </Space>
            </span>
          </Dropdown>
        }
        <Button className={`follow ${ appreciations?.find((appr:any) => appr.fromUserId === user_id) ? 'active' : ''}`} shape="circle" onClick={appreciation}>
          <LikeOutlined className="icon" />
          <span>{intl.formatMessage({ id: 'doctors.follow' })}</span>
        </Button>
        <Button className="view-profile" shape="circle" onClick={onClick}>
          <UserOutlined className="icon" />
          <span>{intl.formatMessage({ id: 'doctors.profile' })}</span>
        </Button>
      </div>
    </div>
  )
})
