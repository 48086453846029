import { notification } from 'antd'
import { history } from '../../main'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import authService from '../../services/auth'

import actions from './actions'
import store from 'store'

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(authService.login, email, password)
  if (response && response.accessToken && response.user) {
    yield put({ type: 'auth/LOAD_CURRENT_ACCOUNT' })
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
    yield history.push('/dashboard')
  } else {
    notification.error({
      message: 'Autentificare nereusita',
      description: response?.response?.data?.message || 'Utilizatorul sau parola sunt incorecte',
    })
  }
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: false,
    },
  })
}
export function* FORGOT_PASSWORD({ payload }) {
  const { email, lang } = payload
  const response = yield call(authService.forgot_password, email, lang);
  if (response && response.status === 'OK') {
    const message = lang === 'ro' ? 'Email-ul a fost trimis!' : 'The email has been sent!';
    notification.success({
      message
    })
    yield history.push('/dashboard')
  }
}
export function* RESET_PASSWORD({ payload }) {
  const { password, passwordConfirmation, token, lang } = payload
  const response = yield call(authService.passwordReset, password, passwordConfirmation, token);
  if (response && response.status === 'OK') {
    const message = lang === 'ro' ? 'Parola contului a fost schimbată cu succes!' : 'Account password has been changed successfully!';
    notification.success({
      message
    })
    yield history.push('/login')
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const accessToken = store.get('accessToken')
  if (accessToken) {
    const response = yield call(authService.currentAccount)
    if (response && response.status === 200 && response?.data?.id) {
      yield put({
        type: 'auth/SET_STATE',
        payload: {
          ...response.data,
          authorized: true,
        },
      })
    } else {
      yield put({ type: 'auth/LOGOUT' })
    }
  } else {
    yield put({ type: 'auth/LOGOUT' })
  }

  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield put({ type: actions.RESET_STATE })
  store.remove('accessToken')
  yield history.push('/')
}

export function* REGISTER_PATIENT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(authService.registerPatient, payload)
  if (response && response.status === 201) {
    notification.success({
      message: 'Înregistrarea a fost realizată cu succes!',
    })
    history.push('login')
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* REGISTER_EXPERT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(authService.registerExpert, payload)
  if (response && response.status === 201) {
    notification.success({
      message: 'Înregistrarea a fost realizată cu succes!',
    })
    history.push('login')
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.REGISTER_PATIENT, REGISTER_PATIENT),
    takeEvery(actions.REGISTER_EXPERT, REGISTER_EXPERT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
