import { notification } from 'antd'
import axios from 'axios'
import store from 'store'

const apiClient = axios.create({
  baseURL: process.env.NX_APP_API_URL || 'https://api.medicontact.ro/api',
})

apiClient.interceptors.request.use((request) => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, (error) => {
  // Errors handling
  const {
    response,
    config: { url },
    ...rest
  } = error

  if (response) {
    const { data } = response
    const hideErrorsUrls = ['/me', '/auth/login']

    if (response && data && hideErrorsUrls.indexOf(url) === -1) {
      notification.error({
        message: data && data.message ? data.message : 'Unable to communicate to api',
      })
    }

    // unauthorized, so log out
    if (data.statusCode === 401 && `${window.location.href || ''}`.indexOf('login') === -1) {
      store.remove('accessToken')
      window.location.href = '/login'
    }
  }
})

export default apiClient
