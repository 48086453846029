import React, { memo, ReactElement, useEffect } from 'react';
import { Avatar, Button } from 'antd';
import clsx from 'clsx'
import { PlusOutlined, MessageOutlined, UserOutlined, ExperimentOutlined, EnvironmentOutlined, CalendarOutlined } from '@ant-design/icons';
import styles from "./styles.module.scss"

interface Collaborator {
  id: number;
  name: string;
  title?: string;
  avatar?: string;
}

interface DoctorSidebarProps {
  avatar?: string;
  name: string;
  branch?: string; // Oftalmologie | Cardiologie
  title?: string; // Oftalmolog | Cardiolog
  clinic?: string; // Medlife
  address?: string;

  collaborators?: Collaborator[];

  onFollowClick?: () => void;
  onMessageClick?: () => void;
  onProfileClick?: () => void;
  onMakeAppointmentClick?: () => void;
  onCollaboratorOpinionClick?: (id: number) => void;
  className?: string;
}

export default memo(({ avatar, name, branch, title, clinic, address, collaborators, onFollowClick, onMessageClick, onProfileClick, onMakeAppointmentClick, onCollaboratorOpinionClick, className }: DoctorSidebarProps) => {
  return (
    <div className={clsx(styles.container, 'medi-doctor-sidebar-component', className)}>
      <div className="doctor-details">
        <Avatar src={avatar || undefined} icon={!avatar && <UserOutlined /> || undefined} shape="circle" size="large"/>

        {name && (<h3>{name}</h3>) || null}
        {branch && (<div className="branch">{branch}</div>) || null}


        {(onFollowClick || onMessageClick || onProfileClick) && (
          <div className="actions">
            {onFollowClick && (<Button icon={<PlusOutlined />} shape="circle" onClick={onFollowClick} title="Urmareste" />) || null}
            {onMessageClick && (<Button icon={<MessageOutlined />} shape="circle" onClick={onMessageClick} title="Mesaj" />) || null}
            {onProfileClick && (<Button icon={<UserOutlined />} shape="circle" onClick={onProfileClick} title="Profil" />) || null}
          </div>
        ) || null}
      </div>
      {(title || clinic || address || onMakeAppointmentClick) && (
        <div className="extra-details">
          {(title || clinic) && (
            <div className="desc-item">
              <ExperimentOutlined />
              <div>
                {title && (<p>{title}</p>) || null}
                {clinic && (<p>{clinic}</p>) || null}
              </div>
            </div>
          ) || null}
          {address && (
            <div className="desc-item">
              <EnvironmentOutlined />
              <div>
                {address && (<p>{address}</p>) || null}
              </div>
            </div>
          ) || null}

          {onMakeAppointmentClick && (<Button icon={<CalendarOutlined />} className="make-appointment-btn" onClick={onMakeAppointmentClick}>Programeaza consultatie</Button>) || null}
        </div>
      ) || null}

      {collaborators?.length && (
        <div className="collaborators">
          <h3>Colaboratori</h3>

          {collaborators.map((collaborator, i) => (
            <div className="collaborator">
              <div className="details">
                <Avatar src={collaborator.avatar || undefined} icon={!collaborator.avatar && <UserOutlined /> || undefined} shape="circle" size="small" />
                <div className="meta">
                  {collaborator.name && <div className="name">{collaborator.name}</div> || null}
                  {collaborator.title && <div className="title">{collaborator.title}</div> || null}
                </div>
              </div>
              {onCollaboratorOpinionClick && <Button onClick={() => onCollaboratorOpinionClick(collaborator.id)}>Cere o parere</Button> || null}
            </div>
          ))}
        </div>
      ) || null}

    </div>
  )
})
