import React, { memo, createElement, useEffect, useState } from 'react'
import { Button, Modal, Form, List, Input, Select, Switch, message } from 'antd'
import { FormOutlined, InboxOutlined } from '@ant-design/icons'
import requestsActions from "../../../redux/requests/actions"
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx'
import { DislikeFilled, DislikeOutlined, LikeFilled, LikeOutlined } from '@ant-design/icons';
import { Avatar, Comment, Tooltip } from 'antd';
import styles from './styles.module.scss'
const { TextArea } = Input;
interface PostReplyProps {
  postId: number
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  doctor: any
}
interface CommentItem {
  author: string;
  avatar: string;
  content: string;
  datetime: string;
}

interface EditorProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
  submitting: boolean;
  value: string;
}

const CommentList = ({ comments }: { comments: CommentItem[] }) => (
  <List
    style={{maxHeight: '45vh', overflow: 'auto'}}
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? 'Părerea' : 'opinie'}`}
    itemLayout="horizontal"
    renderItem={props => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }: EditorProps) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        CREA
      </Button>
    </Form.Item>
  </>
);
export default memo(({open, setOpen, postId, doctor}:PostReplyProps) => {
  const data = useSelector((state: any) => state.requests.replies);
  
  
  const dispatch = useDispatch();
  const [comments, setComments] = useState<CommentItem[]>(data.records);
  const [submitting, setSubmitting] = useState(false);
  const [reply, setReply] = useState('');
  useEffect(()=>{
    dispatch({
      type: requestsActions.GET_REPLIES_POST,
      payload: {
        postId
      }
    })
  }, [])

  useEffect(()=>{
    setComments(data.records)
  }, [data])

  const handleSubmit = () => {
    if (!reply) return;

    setSubmitting(true);
    dispatch({
      type: requestsActions.SUBMIT_REPLY_POST,
      payload: {
        postId,
        reply
      }
    })
    setSubmitting(false);
    setReply('');
    setComments([
      {
        author: `${doctor.firstName} ${doctor.lastName}`,
        avatar: doctor.avatar,
        content: reply,
        datetime: new Date().toDateString(),
      },
      ...comments,
    ]);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReply(e.target.value);
  };
  
  return (
    <div className={clsx(styles.container)}>
      <Modal
        title="Pareri"
        visible={open}
        centered
        maskClosable={false}
        width={'550px'}
        onCancel={()=> setOpen(false)}
        footer={false}
      >
        {doctor.userType === 'doctor' &&
          <Comment
            avatar={<Avatar src={doctor.avatar} alt={`${doctor.firstName} ${doctor.lastName}`} />}
            content={
              <Editor
                onChange={handleChange}
                onSubmit={handleSubmit}
                submitting={submitting}
                value={reply}
              />
            }
          />
        }
        {comments.length > 0 && <CommentList comments={comments} />}
        {comments.length === 0 && <>nu există date</>}
      </Modal>
    </div>
  )
})
