import './assets/styles/global.scss'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import React, { memo, StrictMode, useEffect, useState } from 'react'
import * as ReactDOM from 'react-dom'
import { Provider, useSelector } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { IntlProvider } from 'react-intl'

import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Router from './router'
import { composeWithDevTools } from 'redux-devtools-extension'

import { detectLanguage, getLocaleMessages } from './locales'
import Romanian from './locales/ro'
import English from './locales/en'
import dotObject from 'dot-object'

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development') {
  // middlewares.push(logger) /// too loud for my taste
}
const store = createStore(reducers(history), composeWithDevTools(applyMiddleware(...middlewares)))

sagaMiddleware.run(sagas)

const appLanguages = {
  ro: Romanian,
  en: English,
} as any

const CustomIntlProvider = memo(({ children }) => {
  const currentLocale = useSelector((state: any) => state.misc?.appLanguage)
  
  const getLocaleMessages = (locale: string) => {
    const languageFile = appLanguages[locale] || appLanguages.ro
    return dotObject.dot(languageFile)
  }

  return (
    <IntlProvider locale={currentLocale} messages={getLocaleMessages(currentLocale)}>
      {children}
    </IntlProvider>
  )
})

ReactDOM.render(
  <Provider store={store}>
    <CustomIntlProvider>
      <Router history={history} />
    </CustomIntlProvider>
  </Provider>,
  document.getElementById('root'),
)

export { store, history }
