import React, { memo, ReactElement, useState } from 'react'
import { Avatar, Button, Carousel  } from 'antd'
import { Player } from 'video-react';
import clsx from 'clsx'
import "node_modules/video-react/dist/video-react.css";
import requestsActions from '../../redux/requests/actions';
import { LikeOutlined, CommentOutlined, ShareAltOutlined, UserOutlined } from '@ant-design/icons'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import PostReply from './PostReply/PostReply';

interface WallPostProps {
  postId: number
  title: string
  text: string | ReactElement
  icon?: string
  subtitle?: string
  hashTags?: string[]
  files?: {id:number, file: string, type: string }[]
  likes?: {id:number, postId: number, doctorId: number }[]
  className?: string
  date?: string
  doctor: any
}

export default memo(({ postId, icon, title, subtitle, hashTags, text, files, likes, className, date, doctor }: WallPostProps) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  
  function like() {
    dispatch({
      type: requestsActions.SUBMIT_LIKE_POST,
      payload: {
        postId
      }
    })
    dispatch({
      type: 'doctors/GET_POSTS'
    })
  }
  return (
    <div className={clsx(styles.container, 'medi-wallpost-component', className)}>
      <div className="post-header">
        <Avatar src={icon || undefined} icon={(!icon && <UserOutlined />) || undefined} shape="circle" />

        {((title || subtitle) && (
          <div className="post-title">
            {(title && <div className="title">{title}</div>) || null}
            {(subtitle && <div className="subtitle">{subtitle}</div>) || null}
            {(date && <div className="subtitle">{new Date(date).toLocaleDateString()}</div>) || null}
          </div>
        )) ||
          null}
      </div>
      <div className="post-content">
        {(hashTags && hashTags.length && (
          <div className="hash-tags">
            {hashTags.map((tag, i) => (
              <a key={i}>
                #{tag}
              </a>
            ))}
          </div>
        )) ||
          null}
        <div className="post-text">{text}</div>
      </div>

      { files?.length &&
        <Carousel >
          {files.map((file) => (
            <div className="post-image" key={file.id}>
              { file.type === 'image' ? 
                <img src={file.file} alt={title || 'Post image'} loading="lazy" />
                : ( <Player preload='none'>
                  <source src={file.file} title= {title || 'Post image'}  />
                </Player> )
              }
            </div>
          ))}
        </Carousel>
      }
      <div className="post-footer">
        {doctor.userType === 'doctor' ? (
          <Button className={`${likes?.find( like => like.doctorId === doctor.id && like.postId === postId) ? 'active' : ''} like-btn`} icon={<LikeOutlined />} onClick={like}>
            {likes?.length} Relevant
          </Button>
        ) : (
          <Button className={`like-btn`}>
            {likes?.length} Relevant
          </Button>
        )}
        <Button className="comments-btn" icon={<CommentOutlined />} onClick={() => setOpen(true)}>
          Opinie
        </Button>
        <Button className="share-btn" icon={<ShareAltOutlined />}>
          Share
        </Button>
      </div>
      {open && <PostReply setOpen={setOpen} open={open} postId={postId} doctor={doctor} />}
    </div>
  )
})
