import { all } from 'redux-saga/effects'
import auth from './auth/sagas'
import profile from './profile/sagas'
import misc from './misc/sagas'

import doctors from './doctors/sagas'
import requests from './requests/sagas'

export default function* rootSaga() {
  yield all([
    auth(),
    misc(),
    profile(),
    doctors(),
    requests(),
  ])
}
