import apiClient from '../services/axios'

const requestsService = {}

requestsService.getOutgoingRequests = (params) => {
  return apiClient.get('/requests/outgoing', { params } )
}

requestsService.getIncomingRequests = (params) => {
  return apiClient.get('/requests/incoming', { params } )
}

requestsService.getRequestDetails = (requestId) => {
  return apiClient.get(`/requests/${requestId}` )
}

requestsService.acceptRequest = (requestId) => {
  return apiClient.post(`/requests/${requestId}/accept` )
}

requestsService.declineRequest = (requestId, data) => {
  return apiClient.post(`/requests/${requestId}/decline`, data)
}

requestsService.finalizeRequest = (requestId, data) => {
  return apiClient.post(`/requests/${requestId}/finalize`, data)
}

requestsService.getRequestDocument = (documentId) => {
  return apiClient.get(`/requests/documents/${documentId}` )
}

requestsService.requestSecondOpinion = (data) => {
  return apiClient.post('/doctors/second-opinion', data)
}
requestsService.requestCreatePost = (data) => {
  return apiClient.post('/doctors/create-post', data)
}
requestsService.requestLikePost = (data) => {
  return apiClient.post('/doctors/like-post', data)
}
requestsService.requestReplyPost = (data) => {
  return apiClient.post('/doctors/reply-post', data)
}
requestsService.requestRepliesPost = (data) => {
  return apiClient.post('/doctors/replies-post', data)
}

export default requestsService
