import React, { memo, useCallback } from 'react';
import { Menu, Dropdown, Avatar, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import authActions from "../../../../../../redux/auth/actions"
import styles from './style.module.scss'
import { UserOutlined } from '@ant-design/icons';

export default memo(() => {
  const dispatch = useDispatch()
  const auth = useSelector((state: any) => state.auth)

  const triggerSignOut = useCallback(() => {
    dispatch({ type: authActions.LOGOUT })
  },[])

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
       <a href={"javascript:void(0)"} onClick={triggerSignOut}>
         <i className="fe fe-log-out mr-2" />
         Logout
       </a>
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      className={`${styles.container} user-menu`}
    >
      <div>
        <div className="user-details">
          <div className="details">
            <div className="name">{auth.authorized && `${auth.firstName || ''} ${auth.lastName || ''}` || 'Guest'}</div>
            <div className="role">{auth.authorized && auth.userType || ''}</div>
          </div>
          <Avatar src={auth?.avatar || undefined} icon={!auth?.avatar && <UserOutlined /> || undefined} shape="circle" size="large" className="avatar" />
        </div>
      </div>
    </Dropdown>
  )
})
