import apiClient from '../services/axios'
import store from 'store'

const authService = {}

authService.login = (email, password) => {
  store.remove('accessToken')

  return apiClient
    .post('/auth/login', {
      email,
      password,
    })
    .then((response) => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
      }
      return response.data
    })
    .catch((err) => err)
}
authService.forgot_password = (email, lang) => {
  return apiClient
    .post('/auth/password-recovery', {
      email,
      lang
    })
    .then((response) =>  response.data)
    .catch((err) => err)
}
authService.passwordReset = (password, passwordConfirmation, token, lang) => {
  return apiClient
    .post('/auth/password-reset', { password, passwordConfirmation, token, lang })
    .then((response) =>  response.data)
    .catch((err) => err)
}

authService.registerPatient = (data) => {
  return apiClient.post('/auth/register/patient', data).catch((err) => err)
}

authService.registerExpert = (data) => {
  return apiClient.post('/auth/register/expert', data).catch((err) => err)
}

authService.currentAccount = () => {
  return apiClient.get('/auth/me').catch((err) => err)
}

export default authService
