// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_container__2xIAm {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  position: relative;\n  width: 1200px;\n  max-width: 100%;\n  margin-left: auto;\n  margin-right: auto;\n}\n.styles_container__2xIAm.fluid {\n  width: 100%;\n}\n\n.styles_content__3meD5 {\n  min-height: 100%;\n  width: 100%;\n}\n\n.styles_sidebar__3Qtsl {\n  min-height: 100%;\n  width: 340px;\n  flex-grow: 0;\n  flex-shrink: 0;\n}\n.styles_sidebar__3Qtsl.sticky {\n  height: 100%;\n  max-height: 100%;\n  position: sticky;\n  right: 0;\n  top: 0;\n}\n.styles_sidebar__3Qtsl:first-child {\n  margin-right: 25px;\n}\n.styles_sidebar__3Qtsl:last-child {\n  margin-left: 25px;\n}", ""]);
// Exports
exports.locals = {
	"container": "styles_container__2xIAm",
	"content": "styles_content__3meD5",
	"sidebar": "styles_sidebar__3Qtsl"
};
module.exports = exports;
