import { Space, Button } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserMenu, CreatePost } from "./components"
import { FormattedMessage } from 'react-intl';
import styles from "./styles.module.scss"

export default memo(() => {
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.auth);
  function handleOnBoarding() {
    dispatch({ type: 'doctors/ONBOARD_ACCOUNT' })
  }
  return (
    <>
      { auth.userType === 'doctor' && auth.verified === 1
        && !auth.verifed_stripe_account &&
        (<div className={styles.confirm_stripe}>
          <FormattedMessage id="doctors.confirm_stripe.text" />
          <button onClick={handleOnBoarding}>
            <FormattedMessage id="doctors.confirm_stripe.button" />
          </button>
        </div>)
      }
      <div className={styles.container}>
        { auth.userType === 'doctor' &&
          <Space>
            <CreatePost />
          </Space>
        }
        <div className="sep">
          <UserMenu />
        </div>
      </div>
    </>
    )
})
