// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_container__tgw9L {\n  height: 100%;\n}\n.styles_container__tgw9L .ant-layout {\n  height: 100%;\n}\n.styles_container__tgw9L .ant-layout-header {\n  background: transparent;\n  height: auto;\n  padding: 0 20px;\n  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.04);\n}\n.styles_container__tgw9L .ant-layout-content {\n  padding: 20px 20px;\n  overflow-x: auto;\n}\n.styles_container__tgw9L .ant-layout-content .ant-btn {\n  text-transform: none;\n}\n.styles_container__tgw9L .ant-layout-content .ant-btn:hover {\n  border-color: #3dc1a2;\n  color: initial;\n}\n.styles_container__tgw9L .ant-layout-content .ant-btn.ant-btn-primary {\n  background-color: #3dc1a2;\n  border-color: #3dc1a2;\n  color: #fff;\n}\n.styles_container__tgw9L .ant-layout-content .ant-btn.ant-btn-primary:hover {\n  background-color: #8ad9c7;\n  border-color: #8ad9c7;\n  color: #fff;\n}\n.styles_container__tgw9L .ant-layout-content .ant-btn.ant-btn-secondary {\n  background-color: #3dc1a2;\n  border-color: #3dc1a2;\n  color: #fff;\n}\n.styles_container__tgw9L .ant-layout-content .ant-btn.ant-btn-secondary:hover {\n  background-color: #8ad9c7;\n  border-color: #3dc1a2;\n}", ""]);
// Exports
exports.locals = {
	"container": "styles_container__tgw9L"
};
module.exports = exports;
