import apiClient from '../services/axios'

const accountService = {}

accountService.getUpdateAccount = () => {
  return apiClient.get('/account/update/general' )
}

accountService.updateAccount = (data) => {
  return apiClient.patch('/account/update/general', data)
}
accountService.updateAreaOfExpertize = (data) => {
  return apiClient.patch('/account/update/area-of-expertize', data)
}

accountService.updateCollaborators = (data) => {
  return apiClient.patch('/account/update/collaborators', data)
}

accountService.updateAppreciations = (data) => {
  return apiClient.patch('/account/update/appreciations', data)
}
accountService.updateDoctorVerification = (data) => {
  return apiClient.patch('/account/update/doctor-verification', data)
}

accountService.getUpdateConsultSettings = () => {
  return apiClient.get('/account/update/consult-settings' )
}
accountService.updateConsultSettings = (data) => {
  return apiClient.patch('/account/update/consult-settings', data)
}

accountService.getUpdateCertificateSettings = () => {
  return apiClient.get('/account/update/certificates-settings' )
}
accountService.updatecertificateSettings = (data) => {
  return apiClient.patch('/account/update/certificates-settings', data)
}
export default accountService
