import React, { memo } from 'react';
import { Badge, Button, Dropdown, Menu } from 'antd';
import { WechatOutlined } from '@ant-design/icons';
import styles from "./styles.module.scss"

export default memo(() => {
  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        Some text here
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      className={`${styles.container} notifications`}
    >
      <div>
        <Badge count={3}>
          <Button icon={<WechatOutlined />} shape="round" />
        </Badge>
      </div>
    </Dropdown>

  )
})
