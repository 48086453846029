import React from 'react'
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
export default function Collaborator({ avatar, fullName, speciality, setViewDetails, collaborator } : any ) {
  return (
    <div className='flexy collaborator' onClick={() => setViewDetails(collaborator)}>
        <div>
            <Avatar src={avatar || undefined} icon={!avatar && <UserOutlined /> || undefined} shape="circle" size="small" className="avatar"/>
        </div>
        <div>
            <span>{fullName}</span>
            <div className='specialy'>{speciality}</div>
        </div>
    </div>
  )
}
