// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_container__iR0Dg .comment-action {\n  padding-left: 8px;\n  cursor: \"auto\";\n}\n.styles_container__iR0Dg [class*=-col-rtl] .styles_comment-action__39zVO {\n  padding-right: 8px;\n  padding-left: 0;\n}\n.styles_container__iR0Dg .styles_ghg__2Vg6I {\n  display: none;\n}", ""]);
// Exports
exports.locals = {
	"container": "styles_container__iR0Dg",
	"comment-action": "styles_comment-action__39zVO",
	"ghg": "styles_ghg__2Vg6I"
};
module.exports = exports;
