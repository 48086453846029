import React, { lazy, memo, Suspense } from 'react'
import { History } from 'history'

import { ConnectedRouter } from 'connected-react-router'
import { Redirect, Route, Switch } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import Layout from '../../web-app/src/layouts'

const routerAnimation = 'slide-fadein-up' // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein

const routes: any[] = [
  // {
  //   path: '/somepage',
  //   Component: lazy(() => import('./pages/somepage')),
  //   exact: true,
  // },

  ////////////////////////////////////////

  {
    path: '/',
    Component: lazy(() => import('./pages/home')),
    exact: true,
  },
  // {
  //   path: '/hello',
  //   Component: lazy(() => import('./pages/auth/landing')),
  //   exact: true,
  // },
  // {
  //   path: '/register-patient',
  //   Component: lazy(() => import('./pages/auth/register-patient')),
  //   exact: true,
  // },
  {
    path: '/register-expert',
    Component: lazy(() => import('./pages/auth/register-expert')),
    exact: true,
  },
  {
    path: '/dashboard',
    Component: lazy(() => import('./pages/dashboard')),
    exact: true,
  },
  {
    path: '/doctors',
    Component: lazy(() => import('./pages/doctors/list')),
    exact: true,
  },
  {
    path: '/profile',
    Component: lazy(() => import('./pages/profile')),
    exact: true,
  },
  {
    path: '/requests',
    Component: lazy(() => import('./pages/requests')),
    exact: true,
  },
  {
    path: '/forgot-password',
    Component: lazy(() => import('./pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/password-reset',
    Component: lazy(() => import('./pages/auth/password-reset')),
    exact: true,
  },

  {
    path: '/login',
    Component: lazy(() => import('./pages/auth/login')),
    exact: true,
  },


  {
    path: '/auth/404',
    Component: lazy(() => import('./pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('./pages/auth/500')),
    exact: true,
  },
]

const Router = memo(({ history }: { history: History }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={300}
                >
                  <Switch location={location}>
                    {/* <Route exact path="/" render={() => <Redirect to="/account" />} /> */}
                    {/* <Route exact path="/account" render={() => <Redirect to="/account" />} /> */}
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation} style={{ height: 'inherit' }}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
})

export default Router
