import React, { memo, useCallback, useEffect, useState } from 'react'
import { Button, Modal, Form, Upload, Input, Select, Switch, message } from 'antd'
import { FormOutlined, InboxOutlined } from '@ant-design/icons'
import requestsActions from "./../../../../../../redux/requests/actions"
import styles from './styles.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
const { Option } = Select;
export default memo(() => {
  const [form] = Form.useForm()
  const intl = useIntl();
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const filters = useSelector((state: any) => state.doctors?.filters?.specialties || false );
  const listChildren: React.ReactNode[] = [];
  useEffect(()=>{
    dispatch({ type: 'doctors/GET_FILTERS' })
  }, [])
  const key = 'uploading';
  const uploading = useSelector((state: any) =>  state.requests.requestCreatePostUploaing || false )
  
  const createPost = () => {
    const values = form.getFieldsValue(true);
    
    if(values['documents']?.length === 0 
    || !values['post_details'] || 
    values['post_tags']?.length === 0
    || !values['specialty'] ) {
      return
    }
    const formData = new FormData()
    Object.keys(values).forEach(key => {
      const value = values[key]
      switch(key) {
        case "documents":
          value.map((document: any, i: number) => {
            formData.append(`${key}[${i}]`, document.originFileObj, document.originFileObj.name)
          })
          break;
        case "post_tags":
          formData.append(key, value.join(','))
          break;
        case "only_visible_to_doctors":
          formData.append(key, value ? '1' : '0' )
          break;
        default:
          formData.append(key, typeof value === "object" && JSON.stringify(value) || value)
          break
      }
    })
    dispatch({
      type: requestsActions.SUBMIT_CREATE_POST,
      payload: formData
    })
    setOpen(false);
    form.resetFields();
    message.loading({ content: 'please wait...', key, duration: 0 });
  };
  useEffect(()=>{
    if(uploading === 'uploaded') {
      message.success({ content: 'Post is created', key, duration: 2 });
    }else if (uploading === 'failed'){
      message.error({ content: 'Something Eent Wrong!', key, duration: 2 });
    }
  }, [uploading])
 
  
  return (
    <div >
      <Button type="primary" size="small" icon={<FormOutlined />} onClick={() => setOpen(true)}>
        Create Post
      </Button>
      <Modal
        title="Create Post"
        visible={open}
        centered
        okText={"Create Post"}
        maskClosable={false}
        onOk={createPost}
        width={'100%'}
        onCancel={()=> setOpen(false)}
        className={styles.container}
      >
        <Form
          preserve
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
        >
          <Form.Item
            preserve
            label={"Post Details"}
            name="post_details"
            rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
            >
            <Input.TextArea showCount maxLength={5000} />
          </Form.Item>
          <Form.Item
            preserve
            label={"Post Tags"}
            name="post_tags"
            rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
            >
            <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" >
              {listChildren}
            </Select>
          </Form.Item>
          <Form.Item
            preserve
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label={intl.formatMessage({ id: 'general.second-opinion-modal.sections.step2.fields.documents.label' })}
            name="documents"
            rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
            getValueFromEvent={({ fileList }) => fileList || []}
            valuePropName="fileList"

          >
            <Upload.Dragger
              multiple
              beforeUpload={() => false}
              accept=".jpg,.jpeg,.gif,.png,.tiff,video/mp4,video/x-m4v,video/*"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text"><FormattedMessage id="form.upload-post.title" /></p>
              <p className="ant-upload-hint"><FormattedMessage id="form.upload-post.text" /></p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item
            preserve
            label={"Only Visible To Doctors"}
            name="only_visible_to_doctors"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
          <Form.Item
            preserve
            label={"Specialty"}
            name="specialty"
            rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
            >
           {filters && <Select
              className='filters'
              showSearch
              style={{ width: 200 }}
              placeholder="Search to Select"
              optionFilterProp="children"
            >
              { filters.map((s: { id: number, specialty: string }) =>  ( <Option key={s.id} value={s.specialty}>{s.specialty}</Option> ) ) } 
            </Select>}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
})
