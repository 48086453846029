import actions from './actions'

const initialState = {
  appLanguage: localStorage.getItem('lang') || 'ro',
  academicTitles: [],
  degreeTypes: [],
  specialties: [],
  expertCountries: [],
  expertRegions: [],
}

export default function miscReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_APP_LANGUAGE:
      return { ...state, appLanguage: action.payload }
    default:
      return state
  }
}
