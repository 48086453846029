import React, { memo, ReactElement, useCallback, useEffect } from 'react';
import { Avatar, Button } from 'antd';
import clsx from 'clsx'
import { UserOutlined } from '@ant-design/icons';
import requestsActions from "../../../../redux/requests/actions"
import styles from "./styles.module.scss"
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import Collaborator from './Collaborator';

interface ConsultSettings {
  slotsPerMonth?: number
  price?: number
}
interface Address {
  address?: string
  city?: string
  country?: string
  region?: string
}
interface ListViewItemProps {
  id?: string;
  avatar?: string;
  firstName?: string;
  lastName?: string;
  specialty?: string;
  degreeType?: string;
  academicTitle?: string;
  address?: Address;
  consultSettings?: ConsultSettings;

  className?: string;
  area_of_expertize?: string;
  bio?: string;
  affiliation?: string;
  collaborators?: string;
  doctors: any;
  setViewDetails: any;
  appreciations?: {id: number, fromUserId: number, toUserId: number, userType: string }[];
  verified?: string;
}

export default memo(({ id, avatar, firstName, lastName, specialty, address, degreeType, academicTitle, consultSettings, className, area_of_expertize, bio, affiliation, collaborators, doctors, setViewDetails, appreciations, verified }: ListViewItemProps) => {
  const dispatch = useDispatch()
  const intl = useIntl();
  
  const toggleSecondOpinionModal = useCallback(() => dispatch({
    type: requestsActions.OPEN_REQUEST_MODAL,
    payload: id
  }),[])

  return (
    <div className={clsx(styles.container, 'medi-doctor-table-details-view-item-component', className)}>
      <div className='header'>
        <div className='header_left'>
          <Avatar src={avatar || undefined} icon={!avatar && <UserOutlined /> || undefined} shape="circle" size="large" className="avatar"/>
          <div className="details">
            <h3>{firstName} {lastName}</h3>
            <div className="speciality puerto-rico">{intl.formatMessage({ id: 'doctors.specialty' })} : {specialty}</div>
            {area_of_expertize &&
              <div className="area_of_expertize puerto-rico">
                Expertiese : {area_of_expertize}
              </div>
            }
            <div className="location">
              <i className="fas fa-map-marker-alt mr-1"/>
              {address && address.city || ''}
              {address && address.country && `, ${address.country}` || ''}
            </div>
            {affiliation &&
              <div className="affiliation">
                {intl.formatMessage({ id: 'profile.general.fields.Affiliation.label' })} : {affiliation}
              </div>
            }
          </div>
        </div>
        <div className='header_right'>
          <h4 className={verified ? 'verified yes' : 'verified no'}>{verified ? "Verified" : "Not Verified"}
            {verified ? <i className="fa-solid fa-user-check ml-1"></i> : <i className="fa-solid fa-user-xmark ml-1"></i>}
          </h4>
          <h3>{intl.formatMessage({ id: 'doctors.appreciations' })}</h3>
          <div className='flexy'>
            <div>
              <i className="fas fa-user-doctor mr-2"></i>
            </div>
            <div>
              <span className='nbr_appreciations'>{ appreciations?.filter((appr: any) => appr.type === 'doctor').length }</span>
              <div>{intl.formatMessage({ id: 'doctors.doctors' })}</div>
            </div>
          </div>
          {/* <div className='flexy'>
            <div>
              <i className="fa-solid fa-user mr-2"></i>
            </div>
            <div>
            <span className='nbr_appreciations'>{ appreciations?.filter((appr: any) => appr.userType === 'patient').length }</span>
              <div>{intl.formatMessage({ id: 'doctors.patients' })}</div>
            </div>
          </div> */}
        </div>
      </div>
      <div className='header'>
        <div className='bio'>
          <h3>Biography</h3>
          <div>
            {bio}
          </div>
        </div>
        <div className='header_right'>
          <h3>Collaborators</h3>
          {doctors.filter((doc: any) => collaborators?.split(',').includes(doc.id + '') ).map( (collaborator:any) => (< Collaborator collaborator={collaborator} key={collaborator.id} fullName={`${collaborator.firstName} ${collaborator.lastName}`} avatar={collaborator.avatar} speciality={collaborator.speciality} setViewDetails={setViewDetails}/>))}
        </div>
      </div>
      <div className="request-opinion">
        <ul className='date'>
          <li className='item'>
            <i className="fa-regular fa-clock"></i>
          </li>
          <li className='item'>
            <span>Number of tele-expertise available this month : </span>
            <span className='number'>{consultSettings?.slotsPerMonth || 0 }</span>
          </li>
          <li className='item'>
              <span>Price of tele-expertise : </span>
              <span className='number'>{consultSettings?.price || 0 }</span>
          </li>
        </ul>
        <div>
          {!consultSettings || consultSettings?.slotsPerMonth === 0 ? 
            (<Button disabled={true} >
              <i className="fa-regular fa-calendar-days mr-2"></i>
              No More Empty Slots
            </Button>)
            : 
            (<Button onClick={toggleSecondOpinionModal} >
              <i className="fa-regular fa-calendar-days mr-2"></i>
              Cere a doua opinie
            </Button>)
          }
        </div>
      </div>
    </div>
  )
})
