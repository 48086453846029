// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_container__rV9Dr {\n  background: transparent;\n  border-radius: 20px;\n}\n.styles_container__rV9Dr .ant-checkbox-group .ant-checkbox-wrapper {\n  width: 100%;\n  margin-left: 0 !important;\n}\n.styles_container__rV9Dr .checkbox-section {\n  margin-bottom: 20px;\n}\n.styles_container__rV9Dr .checkbox-section:last-child {\n  margin-bottom: 0;\n}\n.styles_container__rV9Dr .checkbox-section .checkbox-section-title {\n  text-transform: uppercase;\n  font-weight: 500;\n  font-size: 18px;\n  margin: 0;\n  padding: 0;\n}\n.styles_container__rV9Dr .more-specialties {\n  margin-top: 10px;\n  font-size: 12px;\n}", ""]);
// Exports
exports.locals = {
	"container": "styles_container__rV9Dr"
};
module.exports = exports;
