import general from './general.json'
import form from './form.json'
import landing from './landing.json'
import register from './register.json'
import login from './login.json'
import profile from './profile.json'
import requests from './requests.json'
import doctors from './doctors.json'
import forgot_password from './forgot-password.json'
import home from './home.json'

export default {
  general,
  form,
  register,
  login,
  landing,
  profile,
  requests,
  doctors,
  forgot_password,
  home
}
