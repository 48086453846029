import apiClient from '../services/axios'

const miscService = {}

miscService.getAcademicTitlesList = () => {
  return apiClient.get('/misc/academic-titles' )
}

miscService.getDegreeTypesList = () => {
  return apiClient.get('/misc/degree-types' )
}

miscService.getSpecialtiesList = () => {
  return apiClient.get('/misc/specialties' )
}

miscService.getExpertCountriesList = () => {
  return apiClient.get('/misc/expert-countries' )
}

miscService.getExpertRegionsList = (countryId) => {
  return apiClient.get(`/misc/expert-countries/${countryId}/regions` )
}

export default miscService
