import apiClient from '../services/axios'
import store from 'store'

const doctorsService = {}

doctorsService.checkCUIM = (cuim) => {
  return apiClient.post('/doctors/check-cuim', { cuim })
}

doctorsService.getFilters = () => {
  return apiClient.get('/doctors/filters' )
}

doctorsService.getDoctors = (params) => {
  return apiClient.get('/doctors', { params })
}

doctorsService.onboardAccount = () => {
  return apiClient.get('/doctors/onboard-account')
}

doctorsService.patientPay = (id) => {
  return apiClient.post('/doctors/patient-pay', {id} )
}

doctorsService.getPosts = (params) => {
  return apiClient.get('/doctors/posts', { params })
}

export default doctorsService
