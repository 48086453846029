const actions = {
  SET_STATE: 'auth/SET_STATE',
  RESET_STATE: 'auth/RESET_STATE',

  LOGIN: 'auth/LOGIN',
  REGISTER_PATIENT: 'auth/REGISTER_PATIENT',
  REGISTER_EXPERT: 'auth/REGISTER_EXPERT',
  LOAD_CURRENT_ACCOUNT: 'auth/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'auth/LOGOUT',
  FORGOT_PASSWORD: 'auth/FORGOT_PASSWORD',
  RESET_PASSWORD: 'auth/RESET_PASSWORD',
}

export default actions
