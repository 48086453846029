import React, { memo, useEffect } from 'react'
import { Menu } from "antd"
import clsx from 'clsx';
import styles from "./styles.module.scss"

interface OptionItem {
  key: string;
  title: string
  disabled?: boolean
}

interface ProfileMenuSidebarProps {
  current: string;
  options: OptionItem[];
  onChange: (value: any) => void
  className?: string;
}

export default memo(({ current, className, onChange, options = [] }: ProfileMenuSidebarProps) => {
  return (
    <div className={clsx(styles.container, 'medi-profile-menu-sidebar-component', className)}>
      <Menu onClick={(item) => onChange(item.key)} activeKey={current} defaultSelectedKeys={[current]}>
        {options.map(({ key, title, disabled = false }) => (
          <Menu.Item key={key} disabled={disabled}>{title}</Menu.Item>
        ))}
      </Menu>
    </div>
  )
})

