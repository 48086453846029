// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_container__1Rchx {\n  width: 900px !important;\n  max-width: 90%;\n}\n.styles_container__1Rchx textarea {\n  min-height: 150px;\n}", ""]);
// Exports
exports.locals = {
	"container": "styles_container__1Rchx"
};
module.exports = exports;
