import React, { memo, ReactElement } from 'react'
import styles from "./styles.module.scss"

export default memo(({ children }: { children: ReactElement }) => {
  return (
    <div className={styles.container}>
      {children}
    </div>
  )
})
