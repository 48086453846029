import { notification } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions'
import doctorsService from '../../services/doctors';

export function* CHECK_CUIM({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingCuim: true,
    },
  })
  const response = yield call(doctorsService.checkCUIM, payload)
  if (response && response.status === 201) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        cuimData: response.data,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingCuim: false,
    },
  })
}

export function* GET_FILTERS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(doctorsService.getFilters, payload)
  if (response && response.status === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        filters: response.data,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_DOCTORS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(doctorsService.getDoctors, payload)
  if (response && response.status === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        doctors: response.data,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_POSTS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(doctorsService.getPosts, payload)
  if (response && response.status === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        posts: response.data,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}
export function* ONBOARD_ACCOUNT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(doctorsService.onboardAccount, payload)
  if (response && response.status === 200) {
    let url = response.data.url;
    window.location.replace(url);
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* PATIENT_PAY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(doctorsService.patientPay, payload)

  if (response && response.data?.status === "OK") {
    let url = response.data.url;
    window.location.replace(url);
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHECK_CUIM, CHECK_CUIM),
    takeEvery(actions.GET_FILTERS, GET_FILTERS),
    takeEvery(actions.GET_DOCTORS, GET_DOCTORS),
    takeEvery(actions.ONBOARD_ACCOUNT, ONBOARD_ACCOUNT),
    takeEvery(actions.PATIENT_PAY, PATIENT_PAY),
    takeEvery(actions.GET_POSTS, GET_POSTS)
  ])
}
