// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_container__1MIHr {\n  background: transparent;\n  min-width: 200px;\n  margin-left: 40px;\n}\n.style_container__1MIHr .user-details {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  font-size: 14px;\n  line-height: 22px;\n  cursor: pointer;\n}\n.style_container__1MIHr .user-details .avatar {\n  margin-left: 20px;\n  width: 55px;\n  height: 55px;\n  background: #fff;\n  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);\n}\n.style_container__1MIHr .user-details .details {\n  text-align: right;\n}\n.style_container__1MIHr .user-details .details .name {\n  color: #041430;\n  font-weight: 600;\n  white-space: nowrap;\n}\n.style_container__1MIHr .user-details .details .role {\n  font-size: 12px;\n  color: #3dc1a2;\n  text-transform: capitalize;\n}", ""]);
// Exports
exports.locals = {
	"container": "style_container__1MIHr"
};
module.exports = exports;
