const actions = {
  SET_STATE: 'profile/SET_STATE',
  RESET_STATE: 'profile/RESET_STATE',

  GET_UPDATE_ACCOUNT: 'profile/GET_UPDATE_ACCOUNT',
  UPDATE_ACCOUNT: 'profile/UPDATE_ACCOUNT',

  UPDATE_AREA_OF_EXPERTIZE: 'profile/UPDATE_AREA_OF_EXPERTIZE',
  UPDATE_APPRECIATIONS: 'profile/UPDATE_APPRECIATIONS',
  UPDATE_DOCTOR_VERIFICATION: 'profile/UPDATE_DOCTOR_VERIFICATION',

  UPDATE_COLLABORATORS : 'profile/UPDATE_COLLABORATORS',
  GET_UPDATE_CONSULT_SETTINGS: 'profile/GET_UPDATE_CONSULT_SETTINGS',
  UPDATE_CONSULT_SETTINGS: 'profile/UPDATE_CONSULT_SETTINGS',

  GET_UPDATE_CERTIFICATES_SETTINGS: 'profile/GET_UPDATE_CERTIFICATES_SETTINGS',
  UPDATE_CERTIFICATES_SETTINGS: 'profile/UPDATE_CERTIFICATES_SETTINGS',

}

export default actions
