import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions'
import miscService from '../../services/misc';

export function* GET_ACADEMIC_TITLES() {
  const response = yield call(miscService.getAcademicTitlesList)
  if (response && response.status === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        academicTitles: response.data,
      },
    })
  }
}

export function* GET_DEGREE_TYPES() {
  const response = yield call(miscService.getDegreeTypesList)
  if (response && response.status === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        degreeTypes: response.data,
      },
    })
  }
}

export function* GET_SPECIALTIES() {
  const response = yield call(miscService.getSpecialtiesList)
  if (response && response.status === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        specialties: response.data,
      },
    })
  }
}

export function* GET_EXPERT_COUNTRIES() {
  const response = yield call(miscService.getExpertCountriesList)
  if (response && response.status === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        expertCountries: response.data,
      },
    })
  }
}

export function* GET_EXPERT_REGIONS({ payload }) {
  const response = yield call(miscService.getExpertRegionsList, payload)
  if (response && response.status === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        expertRegions: response.data,
      },
    })
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ACADEMIC_TITLES, GET_ACADEMIC_TITLES),
    takeEvery(actions.GET_DEGREE_TYPES, GET_DEGREE_TYPES),
    takeEvery(actions.GET_SPECIALTIES, GET_SPECIALTIES),
    takeEvery(actions.GET_EXPERT_COUNTRIES, GET_EXPERT_COUNTRIES),
    takeEvery(actions.GET_EXPERT_REGIONS, GET_EXPERT_REGIONS),
  ])
}
