import React, { memo, ReactElement } from 'react';
import clsx from 'clsx'
import styles from "./styles.module.scss"

interface ContentProps {
  children: string | ReactElement | ReactElement[] | any
  className?: string
}

interface SidebarProps {
  children: string | ReactElement | ReactElement[];
  sticky?: boolean;
  className?: string
}

interface ContainerProps{
  fluid?: boolean;
  children: string | ReactElement | ReactElement[]
  className?: string
}

const ContentWrapper = memo<ContentProps>(({ children, className }) => {
  return (
    <div className={clsx(styles.content, 'medi-content', className)}>
      {children}
    </div>
  )
})

const SidebarWrapper = memo<SidebarProps>(({ children, sticky, className }) => {
  return (
    <div className={clsx(styles.sidebar, 'medi-sidebar', sticky && 'sticky' , className)}>
      {children}
    </div>
  )
})

class Container extends React.Component<ContainerProps> {
  static Content = ContentWrapper
  static Sidebar = SidebarWrapper

  render() {
    const { className, fluid = false } = this.props;
    return (
      <div className={clsx(styles.container, 'medi-container', className, fluid && 'fluid')}>
        {this.props.children}
      </div>
    )
  }
}

export default Container
