import actions from './actions'

const initialState = {
  outgoingRequests: {
    records: [],
    _metadata: {
      total: 0,
      found: 0,
      take: 20,
      skip: 0
    }
  },
  incomingRequests: {
    records: [],
    _metadata: {
      total: 0,
      found: 0,
      take: 20,
      skip: 0
    }
  },
  replies: {
    records: [],
    _metadata: {
      total: 0,
      found: 0,
      take: 20,
      skip: 0
    }
  },

  loadingOutgoing: false,
  loading: false,
  loadingIncoming: false,

  requestModalOpen: false,
  requestModalLoading: false,
  requestModalDoctorId: false,
  requestModalResponse: false,

  requestDetailsModalOpen: false,
  requestDetailsModalLoading: false,
  requestDetailsModalRequestId: false,
  requestDetailsModalData: false,
  requestDetailsDownloadLoading: false,
  requestCreatePostUploaing: false,
}

export default function doctorReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    // This is for the request second opinion modal
    case actions.OPEN_REQUEST_MODAL:
      return { ...state, requestModalOpen: !state.requestModalOpen, requestModalDoctorId: !state.requestModalOpen && action.payload || false, requestModalResponse: false }
    case actions.CLOSE_REQUEST_MODAL:
      return { ...state, requestModalOpen: false, requestModalDoctorId: false, requestModalResponse: false }

    // This is for the request second opinion details modal
    case actions.OPEN_REQUEST_DETAILS_MODAL:
      return { ...state, requestDetailsModalOpen: !state.requestDetailsModalOpen, requestDetailsModalRequestId: action.payload, requestDetailsModalData: false }
    case actions.CLOSE_REQUEST_DETAILS_MODAL:
      return { ...state, requestDetailsModalOpen: false, requestDetailsModalRequestId: false, requestDetailsModalData: false }

    default:
      return state
  }
}
