const action = {
  SET_STATE: 'doctors/SET_STATE',
  CHECK_CUIM: 'doctors/CHECK_CUIM',

  GET_FILTERS: 'doctors/GET_FILTERS',
  GET_DOCTORS: 'doctors/GET_DOCTORS',
  ONBOARD_ACCOUNT: 'doctors/ONBOARD_ACCOUNT',
  PATIENT_PAY: 'doctors/PATIENT_PAY',

  GET_POSTS: 'doctors/GET_POSTS',

  REQUEST_SECOND_OPINION: 'doctors/REQUEST_SECOND_OPINION',
  SUBMIT_SECOND_OPINION_REQUEST: 'doctors/SUBMIT_SECOND_OPINION_REQUEST',
};

export default action;
