import React, { memo, ReactElement } from 'react'
import { Layout } from 'antd'

import { Sidebar, TopBar } from "./components"
import { SecondOpinionModal, SecondOpinionDetailsModal } from "../../components"
import styles from "./styles.module.scss"
import { useSelector } from 'react-redux';

export default memo(({ children }: { children: ReactElement }) => {
  const { requestModalOpen, requestModalDoctorId, requestDetailsModalOpen, requestDetailsModalRequestId } = useSelector((state: any) => state.requests)

  return (
    <div className={styles.container}>
      <Layout>
        <Sidebar />
        <Layout>
          <Layout.Header>
            <TopBar />
          </Layout.Header>
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            {children}

            {requestModalOpen && (
              <SecondOpinionModal
                visible={!!requestModalOpen}
                doctorId={requestModalDoctorId}
              />
            ) || null}

            {requestDetailsModalOpen && (
              <SecondOpinionDetailsModal
                visible={!!requestDetailsModalOpen}
                requestId={requestDetailsModalRequestId}
              />
            ) || null}

          </Layout.Content>
        </Layout>
      </Layout>
    </div>
  )
})
