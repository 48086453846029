import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions'
import requestsService from '../../services/requests';
import doctorsService from '../../services/doctors';
import { notification } from 'antd';

export function* GET_OUTGOING_REQUESTS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingOutgoing: true,
    },
  })

  const response = yield call(requestsService.getOutgoingRequests, payload)
  if (response && response.status === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        outgoingRequests: response.data,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingOutgoing: false,
    },
  })
}
export function* GET_INCOMING_REQUESTS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingIncoming: true,
    },
  })

  const response = yield call(requestsService.getIncomingRequests, payload)
  if (response && response.status === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        incomingRequests: response.data,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingIncoming: false,
    },
  })
}

export function* SUBMIT_REQUEST_MODAL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      requestModalLoading: true,
    },
  })

  // TODO - move the endpoint to the requests module
  const response = yield call(requestsService.requestSecondOpinion, payload)
  if (response && response.status === 201) {
    notification.success({
      title: 'Success',
      message: response.data && response.data.message || 'Cererea pentru a doua opinie a fost trimisa cu success',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        requestModalResponse: response.data,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      requestModalLoading: false,
    },
  })
}
export function* SUBMIT_CREATE_POST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      requestCreatePostUploaing: true,
    },
  })
  const response = yield call(requestsService.requestCreatePost, payload)
  if (response && response.status === 201) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        requestCreatePostUploaing: 'uploaded', 
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        requestCreatePostUploaing: 'failed'
      },
    })
  }
  
}
export function* SUBMIT_LIKE_POST({ payload }) {
  const response = yield call(requestsService.requestLikePost, payload)
  if (response && response.status === 201) {
    notification.success({
      message: 'Succes',
      description: 'cererea depusă',
    })
  }
}
export function* SUBMIT_REPLY_POST({ payload }) {
  const response = yield call(requestsService.requestReplyPost, payload)
  if (response && response.status === 201) {
    notification.success({
      message: 'Succes',
      description: 'cererea depusă',
    })
  }
}

export function* GET_REPLIES_POST({ payload }) {

  const response = yield call(requestsService.requestRepliesPost, payload)

  if (response && response.status === 201) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        replies: response.data,
      },
    })
  }

}

export function* GET_REQUEST_DETAILS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      requestDetailsModalLoading: true,
    },
  })

  // TODO - move the endpoint to the requests module
  const response = yield call(requestsService.getRequestDetails, payload)
  if (response && response.status === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        requestDetailsModalData: response.data,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      requestDetailsModalLoading: false,
    },
  })
}

export function* DOWNLOAD_REQUEST_DOCUMENT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      requestDetailsDownloadLoading: true,
    },
  })

  // TODO - move the endpoint to the requests module
  const response = yield call(requestsService.getRequestDocument, payload)
  if (response && response.status === 200) {
    window.open(response.data.url, '_blank')
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      requestDetailsDownloadLoading: false,
    },
  })
}

export function* ACCEPT_REQUEST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      requestDetailsModalLoading: true,
    },
  })

  const response = yield call(requestsService.acceptRequest, payload)
  if (response && response.status === 201) {
    yield put({
      type: actions.GET_REQUEST_DETAILS,
      payload,
    })
    notification.success({
      message: 'Succes',
      description: 'Solicitarea a fost acceptata',
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      requestDetailsModalLoading: false,
    },
  })
}

export function* DECLINE_REQUEST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      requestDetailsModalLoading: true,
    },
  })

  const response = yield call(requestsService.declineRequest, payload.requestId, payload)
  if (response && response.status === 201) {
    yield put({
      type: actions.GET_REQUEST_DETAILS,
      payload: payload.requestId,
    })
    notification.success({
      message: 'Succes',
      description: 'Solicitarea a fost refuzata',
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      requestDetailsModalLoading: false,
    },
  })
}

export function* FINALIZE_REQUEST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      requestDetailsModalLoading: true,
    },
  })

  const response = yield call(requestsService.finalizeRequest, payload.requestId, payload)
  if (response && response.status === 201) {
    yield put({
      type: actions.GET_REQUEST_DETAILS,
      payload: payload.requestId,
    })
    notification.success({
      message: 'Succes',
      description: 'Solicitarea a fost finalizata cu succes!',
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      requestDetailsModalLoading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_OUTGOING_REQUESTS, GET_OUTGOING_REQUESTS),
    takeEvery(actions.GET_INCOMING_REQUESTS, GET_INCOMING_REQUESTS),
    takeEvery(actions.SUBMIT_REQUEST_MODAL, SUBMIT_REQUEST_MODAL),
    takeEvery(actions.SUBMIT_CREATE_POST, SUBMIT_CREATE_POST),
    takeEvery(actions.SUBMIT_LIKE_POST, SUBMIT_LIKE_POST),
    takeEvery(actions.SUBMIT_REPLY_POST, SUBMIT_REPLY_POST),
    takeEvery(actions.GET_REPLIES_POST, GET_REPLIES_POST),
    takeEvery(actions.GET_REQUEST_DETAILS, GET_REQUEST_DETAILS),
    takeEvery(actions.DOWNLOAD_REQUEST_DOCUMENT, DOWNLOAD_REQUEST_DOCUMENT),
    takeEvery(actions.ACCEPT_REQUEST, ACCEPT_REQUEST),
    takeEvery(actions.DECLINE_REQUEST, DECLINE_REQUEST),
    takeEvery(actions.FINALIZE_REQUEST, FINALIZE_REQUEST),
  ])
}
