import React, { memo, ReactElement } from 'react'
import { Link } from 'react-router-dom';

import styles from "./styles.module.scss"

export default memo(({ children }: { children: ReactElement }) => {
  return (
    <div className={styles.container}>
      <div className="website-header">
        <Link to="/">
          <img src="/images/logo.svg" alt="Medicontact" />
        </Link>
      </div>
      <div className="website-content">
        {children}
      </div>
    </div>
  )
})
