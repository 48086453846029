// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_container__1Bmp0 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: row;\n}\n.styles_container__1Bmp0 a {\n  display: block;\n  margin: 5px;\n}\n.styles_container__1Bmp0 a img {\n  width: 30px;\n  height: 20px;\n  object-fit: cover;\n  border-radius: 5px;\n}", ""]);
// Exports
exports.locals = {
	"container": "styles_container__1Bmp0"
};
module.exports = exports;
