// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_container__22erW .ant-input {\n  padding-top: 15px;\n  padding-bottom: 15px;\n  border-radius: 40px !important;\n}\n.styles_container__22erW textarea {\n  min-height: 400px;\n  height: 400px;\n  max-height: 70vh;\n}\n.styles_container__22erW .ant-modal-footer {\n  text-align: center !important;\n}\n.styles_container__22erW .styles_ant-modal-footer__11844 {\n  text-align: center !important;\n}", ""]);
// Exports
exports.locals = {
	"container": "styles_container__22erW",
	"ant-modal-footer": "styles_ant-modal-footer__11844"
};
module.exports = exports;
