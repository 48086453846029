import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import auth from './auth/reducers';
import misc from './misc/reducers';
import profile from './profile/reducers';

import doctors from './doctors/reducers';
import requests from './requests/reducers';

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    misc,
    profile,
    requests,
    doctors,
  });
