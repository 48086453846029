const action = {
  SET_STATE: 'misc/SET_STATE',
  SET_APP_LANGUAGE: 'misc/SET_APP_LANGUAGE',
  GET_ACADEMIC_TITLES: 'misc/GET_ACADEMIC_TITLES',
  GET_DEGREE_TYPES: 'misc/GET_DEGREE_TYPES',
  GET_SPECIALTIES: 'misc/GET_SPECIALTIES',
  GET_EXPERT_COUNTRIES: 'misc/GET_EXPERT_COUNTRIES',
  GET_EXPERT_REGIONS: 'misc/GET_EXPERT_REGIONS',
};

export default action;
