import { all, call, put, takeEvery } from 'redux-saga/effects'
import accountService from '../../services/account'

import actions from './actions'
import { notification } from 'antd';

export function* GET_UPDATE_ACCOUNT() {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(accountService.getUpdateAccount)
  if (response && response.status === 200) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        general: response.data,
      },
    })
  } else {
    notification.error({
      message: 'Eroare',
      description: response?.response?.data?.message || "Nu s-au putut comunica cu backendul"
    })
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: false,
    },
  })
}
export function* UPDATE_ACCOUNT({ payload }) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(accountService.updateAccount, payload)
  if (response && response.status === 200) {
    yield put({ type: 'profile/GET_UPDATE_ACCOUNT' })
    yield put({ type: 'auth/LOAD_CURRENT_ACCOUNT' })
    notification.success({
      message: 'Succes!',
      description: response?.response?.data?.message || "Setarile au fost salvate"
    })
  } else {
    notification.error({
      message: 'Eroare',
      description: response?.response?.data?.message || "Nu s-au putut comunica cu backendul"
    })
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: false,
    },
  })
}
export function* UPDATE_AREA_OF_EXPERTIZE({ payload }) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(accountService.updateAreaOfExpertize, payload)
  if (response && response.status === 200) {
    yield put({ type: 'profile/GET_UPDATE_ACCOUNT' })
    yield put({ type: 'auth/LOAD_CURRENT_ACCOUNT' })
    notification.success({
      message: 'Succes!',
      description: response?.response?.data?.message || "Setarile au fost salvate"
    })
  } else {
    notification.error({
      message: 'Eroare',
      description: response?.response?.data?.message || "Nu s-au putut comunica cu backendul"
    })
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: false,
    },
  })
}
export function* UPDATE_APPRECIATIONS({ payload }) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(accountService.updateAppreciations, payload)
  if (response && response.status === 200) {
    yield put({ type: 'profile/GET_UPDATE_ACCOUNT' })
    yield put({ type: 'auth/LOAD_CURRENT_ACCOUNT' })
    notification.success({
      message: 'Succes!',
      description: response?.response?.data?.message || "Setarile au fost salvate"
    })
  } else {
    notification.error({
      message: 'Eroare',
      description: response?.response?.data?.message || "Nu s-au putut comunica cu backendul"
    })
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: false,
    },
  })
}
export function* UPDATE_DOCTOR_VERIFICATION({ payload }) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(accountService.updateDoctorVerification, payload)
  if (response && response.status === 200) {
    yield put({ type: 'profile/GET_UPDATE_ACCOUNT' })
    yield put({ type: 'auth/LOAD_CURRENT_ACCOUNT' })
    notification.success({
      message: 'Succes!',
      description: response?.response?.data?.message || "Setarile au fost salvate"
    })
  } else {
    notification.error({
      message: 'Eroare',
      description: response?.response?.data?.message || "Nu s-au putut comunica cu backendul"
    })
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: false,
    },
  })
}
export function* UPDATE_COLLABORATORS({ payload }) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(accountService.updateCollaborators , payload)
  if (response && response.status === 200) {
    yield put({ type: 'profile/GET_UPDATE_ACCOUNT' })
    yield put({ type: 'auth/LOAD_CURRENT_ACCOUNT' })
    notification.success({
      message: 'Succes!',
      description: response?.response?.data?.message || "Setarile au fost salvate"
    })
  } else {
    notification.error({
      message: 'Eroare',
      description: response?.response?.data?.message || "Nu s-au putut comunica cu backendul"
    })
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_UPDATE_CONSULT_SETTINGS() {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(accountService.getUpdateConsultSettings)
  if (response && response.status === 200) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        consultSettings: response.data,
      },
    })
  } else {
    notification.error({
      message: 'Eroare',
      description: response?.response?.data?.message || "Nu s-au putut comunica cu backendul"
    })
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: false,
    },
  })
}
export function* UPDATE_CONSULT_SETTINGS({ payload }) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(accountService.updateConsultSettings, payload)
  if (response && response.status === 200) {
    yield put({ type: 'profile/GET_UPDATE_CONSULT_SETTINGS' })
    notification.success({
      message: 'Succes!',
      description: response?.response?.data?.message || "Setarile au fost salvate"
    })
  } else {
    notification.error({
      message: 'Eroare',
      description: response?.response?.data?.message || "Nu s-au putut comunica cu backendul"
    })
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_UPDATE_CERTIFICATES_SETTINGS() {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(accountService.getUpdateCertificateSettings)
  if (response && response.status === 200) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        certificatesSettings: response.data,
      },
    })
  } else {
    notification.error({
      message: 'Eroare',
      description: response?.response?.data?.message || "Nu s-au putut comunica cu backendul"
    })
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: false,
    },
  })
}
export function* UPDATE_CERTIFICATES_SETTINGS({ payload }) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(accountService.updatecertificateSettings, payload)
  if (response && response.status === 200) {
    yield put({ type: 'profile/GET_UPDATE_CERTIFICATES_SETTINGS' })
    notification.success({
      message: 'Succes!',
      description: response?.response?.data?.message || "Setarile au fost salvate"
    })
  } else {
    notification.error({
      message: 'Eroare',
      description: response?.response?.data?.message || "Nu s-au putut comunica cu backendul"
    })
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_UPDATE_ACCOUNT, GET_UPDATE_ACCOUNT),
    takeEvery(actions.UPDATE_ACCOUNT, UPDATE_ACCOUNT),
    takeEvery(actions.UPDATE_AREA_OF_EXPERTIZE, UPDATE_AREA_OF_EXPERTIZE),
    takeEvery(actions.UPDATE_APPRECIATIONS, UPDATE_APPRECIATIONS),
    takeEvery(actions.UPDATE_DOCTOR_VERIFICATION, UPDATE_DOCTOR_VERIFICATION),
    takeEvery(actions.UPDATE_COLLABORATORS, UPDATE_COLLABORATORS),
    takeEvery(actions.GET_UPDATE_CONSULT_SETTINGS, GET_UPDATE_CONSULT_SETTINGS),
    takeEvery(actions.UPDATE_CONSULT_SETTINGS, UPDATE_CONSULT_SETTINGS),
    takeEvery(actions.GET_UPDATE_CERTIFICATES_SETTINGS, GET_UPDATE_CERTIFICATES_SETTINGS),
    takeEvery(actions.UPDATE_CERTIFICATES_SETTINGS, UPDATE_CERTIFICATES_SETTINGS),
  ])
}
