// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_confirm_stripe__2O3KY {\n  text-align: center;\n  margin-bottom: -30px;\n}\n.styles_confirm_stripe__2O3KY button {\n  padding-left: 5px;\n  border: none;\n  background: none;\n  color: #ff4d4f;\n  cursor: pointer;\n}\n\n.styles_container__3--ud {\n  color: #333;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center;\n  justify-content: space-between;\n  padding: 20px 0;\n}\n.styles_container__3--ud .sep {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center;\n  justify-content: flex-end;\n}\n.styles_container__3--ud .sep > * {\n  margin-left: 20px;\n}\n.styles_container__3--ud .sep > *:first-child {\n  margin-left: 0;\n}\n.styles_container__3--ud .search-bar {\n  width: 400px;\n  max-width: 600px;\n}\n.styles_container__3--ud > * {\n  margin-left: 20px;\n}\n.styles_container__3--ud > *:first-child {\n  margin-left: 0;\n  flex-grow: 1;\n}", ""]);
// Exports
exports.locals = {
	"confirm_stripe": "styles_confirm_stripe__2O3KY",
	"container": "styles_container__3--ud"
};
module.exports = exports;
