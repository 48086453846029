import actions from './actions'

const initialState = {
  cuimData: {},
  filters: {},
  doctors: {
    records: [],
    _metadata: {
      total: 0,
      found: 0,
      take: 20,
      skip: 0
    }
  },
  secondOpinionDoctorId: false,
  secondOpinionResponse: false,

  loading: false,
  loadingFilters: false,
  loadingCuim: false,
  loadingSecondOpinionRequest: false,
}

export default function doctorReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.REQUEST_SECOND_OPINION:
      return { ...state, secondOpinionDoctorId: action.payload, secondOpinionResponse: false }
    default:
      return state
  }
}
