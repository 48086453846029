import React, { memo, useCallback, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Badge, Button, Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom'
import { LanguageSwitcher } from "../../../../components"
import store from 'store'
import styles from "./styles.module.scss"
import { FileDoneOutlined, HomeOutlined, MedicineBoxOutlined, UnlockOutlined, UserOutlined, ControlOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import authActions from '../../../../redux/auth/actions';
import { useIntl } from 'react-intl';

export default memo(() => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const menuData:any = [];
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])

  const auth = useSelector((state: any) => state.auth)

  const triggerSignOut = useCallback(() => {
    dispatch({ type: authActions.LOGOUT })
  },[])

  // useEffect(() => {
  //   applySelectedKeys()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname, menuData])
  //
  // const applySelectedKeys = () => {
  //   const flattenItems = (items:any, key:any) =>
  //     items.reduce((flattenedItems:any, item:any) => {
  //       flattenedItems.push(item)
  //       if (Array.isArray(item[key])) {
  //         return flattenedItems.concat(flattenItems(item[key], key))
  //       }
  //       return flattenedItems
  //     }, [])
  //   const tmp = pathname.split('/')
  //   const selectedItem = find(flattenItems(menuData, 'children'), ['url', `/${tmp[1]}`])
  //   setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  // }

  const onOpenChange = (keys: any) => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }

  const handleClick = (e:any) => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  return (
    <Layout.Sider
      className={styles.container}
      width={300}
    >
      <div className="logo-container">
        <img src="/images/logo.svg" alt="Medicontact" />
      </div>
      <PerfectScrollbar>
        <Menu
          onClick={handleClick}
          selectedKeys={selectedKeys}
          openKeys={openedKeys}
          onOpenChange={onOpenChange}
          mode="inline"
          className="side-navigation"
        >
          <Menu.Item key="dashboard" icon={<HomeOutlined className="menu-item" />}>
            <Link to={"/dashboard"}>
              <span className="title">{intl.formatMessage({ id: 'general.menu.dashboard' })}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="doctors" icon={<MedicineBoxOutlined className="menu-item" />}>
            <Link to={"/doctors"}>
              <span className="title">{intl.formatMessage({ id: 'general.menu.doctors' })}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="profile" icon={<UserOutlined className="menu-item" />}>
            <Link to={"/profile"}>
              <span className="title">{intl.formatMessage({ id: 'general.menu.profile' })}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="requests" icon={<FileDoneOutlined className="menu-item" />}>
            <Link to={"/requests"}>
              <span className="title">{intl.formatMessage({ id: 'general.menu.requests' })}</span>
              {auth?.userType === 'doctor' && auth?.pendingRequests && (<span className="badge badge-success ml-2">{auth?.pendingRequests}</span>) || null}
            </Link>
          </Menu.Item>
        </Menu>
      </PerfectScrollbar>
      <LanguageSwitcher />
      <Button className="logout-button" onClick={triggerSignOut}>
        <UnlockOutlined className="menu-item" /> {intl.formatMessage({ id: 'general.menu.sign-out' })}
      </Button>
    </Layout.Sider>
  )
})
