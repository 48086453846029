import React, { memo, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx'
import { InboxOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Input, Upload, Modal, Steps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import requestsActions from "../../redux/requests/actions"
import styles from "./styles.module.scss"


interface SecondOpinionModalProps {
  visible?: boolean;
  doctorId: number;
  className?: string;
}

export default memo(({ visible, doctorId, className }: SecondOpinionModalProps) => {
  const [form] = Form.useForm()

  const intl = useIntl()
  const dispatch = useDispatch()

  const [wizzardStep, setWizzardStep] = useState(0)
  const [wizzardFormValues, setWizzardFormValues] = useState({}) as any
  const [nextStepActive, setNextStepActive] = useState(false)

  const secondOpinionResponse = useSelector((state: any) => state.requests.requestModalResponse || false)

  const handleSubmitForm = useCallback(() => {
    const values = form.getFieldsValue(true)
    const formData = new FormData()
    formData.append('doctorId', `${doctorId}`)
    Object.keys(values).map(key => {
      const value = values[key]

      switch(key) {
        case "documents":
          value.map((document: any, i: number) => {
            formData.append(`${key}[${i}]`, document.originFileObj, document.originFileObj.name)
          })
          break;
        default:
          formData.append(key, typeof value === "object" && JSON.stringify(value) || value)
          break
      }
    })

    dispatch({
      type: requestsActions.SUBMIT_REQUEST_MODAL,
      payload: formData
    })
  },[doctorId])

  const triggerNextStep = useCallback(() => setWizzardStep(step => step + 1),[wizzardStep])
  const triggerPrevStep = useCallback(() => setWizzardStep(step => step - 1),[wizzardStep])
  const triggerCloseModal = useCallback(() => {
    dispatch({ type: requestsActions.CLOSE_REQUEST_MODAL })
    setWizzardStep(0)
  },[])


  useEffect(() => {
    // const { diagnostic, symptoms, history, reason, documents } = wizzardFormValues
    const { diagnostic } = wizzardFormValues;
    switch (wizzardStep) {
      case 0:
        // setNextStepActive(!diagnostic || !symptoms || !history || !reason)
        setNextStepActive(!diagnostic)
        break;
      // case 1:
      //   setNextStepActive(!!(documents && documents.length))
      //   break;
    }
  },[wizzardStep, wizzardFormValues])

  return (
    <Modal
      className={clsx(styles.container, 'medi-second-opinion-modal-component', className)}
      title={intl.formatMessage({ id: 'general.second-opinion-modal.title' })}
      visible={visible}
      onCancel={triggerCloseModal}
      footer={false}
    >
      <Form
        preserve
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleSubmitForm}
        autoComplete="off"
        onValuesChange={(changedValues, allValues) => setWizzardFormValues(form.getFieldsValue(true))}
      >
        <Steps current={wizzardStep} className="mb-5">
          <Steps.Step key={1} title={intl.formatMessage({ id: 'general.second-opinion-modal.sections.step1.title' })} />
          <Steps.Step key={2} title={intl.formatMessage({ id: 'general.second-opinion-modal.sections.step2.title' })} />
          <Steps.Step key={3} title={intl.formatMessage({ id: 'general.second-opinion-modal.sections.step3.title' })} />
        </Steps>

        {secondOpinionResponse && (
          <div>
            <h3 className="text-center">{intl.formatMessage({ id: `general.second-opinion-modal.response.${secondOpinionResponse && secondOpinionResponse.status && secondOpinionResponse.status === "OK" && 'success' || 'error'}.title` })}</h3>
            <p className="text-center">{intl.formatMessage({ id: `general.second-opinion-modal.response.${secondOpinionResponse && secondOpinionResponse.status && secondOpinionResponse.status === "OK" && 'success' || 'error'}.text` })}</p>

            <div className="text-center mt-3">
              <Button type="primary" onClick={triggerCloseModal} >
                <FormattedMessage id="general.btn-close" />
              </Button>
            </div>
          </div>
        ) || (
          <div>
            {wizzardStep === 0 && (
              <div>
                <Form.Item
                  preserve
                  label={intl.formatMessage({ id: 'general.second-opinion-modal.sections.step1.fields.diagnostic.label' })}
                  name="diagnostic"
                  rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  preserve
                  label={intl.formatMessage({ id: 'general.second-opinion-modal.sections.step1.fields.symptoms.label' })}
                  name="symptoms"
                  // rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  preserve
                  label={intl.formatMessage({ id: 'general.second-opinion-modal.sections.step1.fields.history.label' })}
                  name="history"
                  // rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  preserve
                  label={intl.formatMessage({ id: 'general.second-opinion-modal.sections.step1.fields.reason.label' })}
                  name="reason"
                  // rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
                >
                  <Input.TextArea />
                </Form.Item>

                <div className="text-center">
                  <Button type="primary" onClick={triggerNextStep} disabled={nextStepActive}>
                    <FormattedMessage id="general.second-opinion-modal.btn-next" />
                  </Button>
                </div>
              </div>
            ) || null}
            {wizzardStep === 1 && (
              <div>
                <Form.Item
                  preserve
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={intl.formatMessage({ id: 'general.second-opinion-modal.sections.step2.fields.documents.label' })}
                  name="documents"
                  // rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
                  getValueFromEvent={({ fileList }) => fileList || []}
                  valuePropName="fileList"

                >
                  <Upload.Dragger
                    multiple
                    beforeUpload={() => false}
                    accept=".jpg,.jpeg,.gif,.png,.tiff,.doc,.docx,.pdf"
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text"><FormattedMessage id="form.upload.title" /></p>
                    <p className="ant-upload-hint"><FormattedMessage id="form.upload.text" /></p>
                  </Upload.Dragger>
                </Form.Item>

                <div className="text-center">
                  <Button onClick={triggerPrevStep} className="mr-2">
                    <FormattedMessage id="general.second-opinion-modal.btn-prev" />
                  </Button>
                  <Button type="primary" onClick={triggerNextStep} disabled={nextStepActive}>
                    <FormattedMessage id="general.second-opinion-modal.btn-next" />
                  </Button>
                </div>
              </div>
            ) || null}

            {wizzardStep === 2 && (
              <div>
                <Form.Item
                  preserve
                  label={intl.formatMessage({ id: 'general.second-opinion-modal.sections.step1.fields.diagnostic.label' })}
                  name="diagnostic"
                  rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
                >
                  <Input.TextArea disabled />
                </Form.Item>
                <Form.Item
                  preserve
                  label={intl.formatMessage({ id: 'general.second-opinion-modal.sections.step1.fields.symptoms.label' })}
                  name="symptoms"
                  // rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
                >
                  <Input.TextArea disabled />
                </Form.Item>
                <Form.Item
                  preserve
                  label={intl.formatMessage({ id: 'general.second-opinion-modal.sections.step1.fields.history.label' })}
                  name="history"
                  // rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
                >
                  <Input.TextArea disabled />
                </Form.Item>
                <Form.Item
                  preserve
                  label={intl.formatMessage({ id: 'general.second-opinion-modal.sections.step1.fields.reason.label' })}
                  name="reason"
                  // rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
                >
                  <Input.TextArea disabled />
                </Form.Item>

                <Form.Item
                  name="documents"
                  label={intl.formatMessage({ id: 'general.second-opinion-modal.sections.step2.fields.documents.label' })}
                  // rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
                  getValueFromEvent={({ fileList }) => fileList || []}
                >

                  {wizzardFormValues?.documents && Array.isArray(wizzardFormValues?.documents) && (
                    <ul>
                      {wizzardFormValues?.documents.map((document: any, i: number) => <li key={i}>{document.name}</li>)}
                    </ul>
                  ) || null}
                </Form.Item>

                <div className="text-center">
                  <Button onClick={triggerPrevStep} className="mr-2">
                    <FormattedMessage id="general.second-opinion-modal.btn-prev" />
                  </Button>
                  <Button type="primary" htmlType="submit">
                    <FormattedMessage id="general.second-opinion-modal.btn-submit" />
                  </Button>
                </div>
              </div>
            ) || null}
          </div>
        )}




      </Form>
    </Modal>
  )
})
