// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_container__2L8w7 {\n  background: #fff;\n  height: 100%;\n}\n.styles_container__2L8w7 .someClass {\n  background: red;\n}", ""]);
// Exports
exports.locals = {
	"container": "styles_container__2L8w7"
};
module.exports = exports;
