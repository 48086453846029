const action = {
  SET_STATE: 'requests/SET_STATE',

  OPEN_REQUEST_MODAL: 'requests/OPEN_REQUEST_MODAL',
  CLOSE_REQUEST_MODAL: 'requests/CLOSE_REQUEST_MODAL',
  SUBMIT_REQUEST_MODAL: 'requests/SUBMIT_REQUEST_MODAL',
  SUBMIT_CREATE_POST: 'requests/SUBMIT_CREATE_POST',
  SUBMIT_LIKE_POST: 'requests/SUBMIT_LIKE_POST',
  SUBMIT_REPLY_POST: 'requests/SUBMIT_REPLY_POST',
  GET_REPLIES_POST: 'requests/GET_REPLIES_POST',

  OPEN_REQUEST_DETAILS_MODAL: 'requests/OPEN_REQUEST_DETAILS_MODAL',
  CLOSE_REQUEST_DETAILS_MODAL: 'requests/CLOSE_REQUEST_DETAILS_MODAL',
  GET_REQUEST_DETAILS: 'requests/GET_REQUEST_DETAILS',
  DOWNLOAD_REQUEST_DOCUMENT: 'requests/DOWNLOAD_REQUEST_DOCUMENT',
  ACCEPT_REQUEST: 'requests/ACCEPT_REQUEST',
  DECLINE_REQUEST: 'requests/DECLINE_REQUEST',
  FINALIZE_REQUEST: 'requests/FINALIZE_REQUEST',

  GET_OUTGOING_REQUESTS: 'requests/GET_OUTGOING_REQUESTS',
  GET_INCOMING_REQUESTS: 'requests/GET_INCOMING_REQUESTS',
};

export default action;
