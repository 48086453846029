import React, { Fragment, memo } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, useLocation } from 'react-router-dom'

import AuthLayout from './Auth'
import MainLayout from './Main'
import PublicLayout from './Public'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
}
let previousPath = ''

export default memo(({ children }: { children: React.ReactElement }) => {
  const { pathname, search } = useLocation()
  const auth = { authorized: true, loading: false }
  
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
  }
  setTimeout(() => {
    previousPath = currentPath
  }, 300)

  const getLayout = () => {
    if(pathname === '/') return 'public';
    if (/^\/(landing|forgot-password|password-reset)(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    if (/^\/(auth|register-patient|register-expert|login)(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = (auth && auth.authorized) || false
  const isUserLoading = (auth && auth.loading) || false
  const isAuthLayout = getLayout() === 'auth'

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized) {
      return <Redirect to="/auth/login" />
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="Medicontact | %s" title="" />
      {BootstrappedLayout()}
    </Fragment>
  )
})
